
























import { Component, Vue } from 'vue-property-decorator'
import { Shop } from '@/api'
import { GameModule } from '@/store/modules'

@Component({
  components: {
    CouponCard: () => import('./CouponCard.vue'),
    CouponModal: () => import('./CouponModal.vue')
  }
})
export default class CouponInventory extends Vue {
  @GameModule.State('characters') charactersFromAPI

  couponStashes = []

  async setCouponStashes() {
    this.couponStashes = await Shop.getCouponsStash()
  }

  async mounted() {
    this.$root.$on('refreshCouponStash', async () => this.setCouponStashes())

    this.$root.$emit('refreshCouponStash')
  }

  beforeDestroy() {
    this.$root.$off('refreshCouponStash', async () => this.setCouponStashes())
  }
}
